<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<div class="config-content">
			<!--全局参数-->
			<h2>全局参数</h2>
			<ul class="list">
				<li :key="index" v-for="(item, index) in globalListData">
					<div class="li-wrap" v-if="item.code != 'IS_OPEN_PROJECT_LIST'">
						<div
							v-if="item.code === 'IS_WATER_MARKED'"
							class="water-mark-rule"
							style="
								padding: 5px 0 10px;
								border-bottom: 1px dashed #eee;
								margin-bottom: 8px;
							"
						>
							<p style="padding-bottom: 6px">水印名称生成规则：</p>
							<Poptip
								title="水印名称生成规则"
								trigger="hover"
								transfer
								:offset="130"
								placement="top-start"
								class="pop-rule"
							>
								<Input
									style="width: 70%"
									v-model="watermarkRule"
									placeholder="点击查看 水印名称生成规则, 适用于预览及下载水印；不填，默认是中心名称+登录人姓名"
								/>
								<div slot="content" class="formitem-tooltip-content">
									<p><span>{ siteName }</span>中心名称</p>
									<p><span>{ userName }</span>登录人姓名</p>
									<p style="margin-top: 10px">例如：{siteName}-{userName}</p>
								</div>
							</Poptip>
						</div>
						<p>{{ item.descr }}</p>
						<p style="margin-top: 10px">
							<i-switch
								:value="!!item.value"
								@on-change="checked => switchChange(index, checked)"
							>
								<span slot="open">开</span>
								<span slot="close">关</span>
							</i-switch>
						</p>
					</div>

					<!--是否开启退回邮件提醒-->
					<Form
						v-if="item.code === 'IS_EMAIL_NOTICE' && item.value"
						style="padding: 10px"
						:model="item"
						ref="emailForm"
						label-position="top"
					>
						<Row :gutter="32">
							<Col span="24">
								<FormItem
									label="是否发送邮件给项目联系人"
									prop="sendToTrialContactUser"
								>
									<i-switch
										:value="!!item.sendToTrialContactUser"
										@on-change="
											checked =>
												switchChange(index, checked, 'sendToTrialContactUser')
										"
									>
										<span slot="open">开</span>
										<span slot="close">关</span>
									</i-switch>
								</FormItem>
							</Col>
							<Col span="24">
								<FormItem label="选择邮件提醒角色" prop="roles">
									<Select
										filterable
										v-model="item.roles"
										multiple
										placeholder="请选择选择邮件提醒角色"
										style="width: 80%"
									>
										<Option
											v-for="list in roleList"
											:value="list.id"
											:key="list.id"
											:label="list.name"
										></Option>
									</Select>
								</FormItem>
							</Col>
							<!-- <Col span="6">
              <FormItem label="邮箱账号"
                        prop="user">
                <Input v-model="item.user"
                       placeholder="请输入邮箱账号" />
              </FormItem>
              </Col>
              <Col span="6">
              <FormItem label="密码"
                        prop="password">
                <Input type="password"
                       readonly
                       @on-focus="e=>e.target.removeAttribute('readonly')"
                       v-model="item.password"
                       placeholder="请输入密码" />
              </FormItem>
              </Col>
              <Col span="6">
              <FormItem label="SMTP服务器"
                        prop="host">
                <Input v-model="item.host"
                       placeholder="请输入SMTP服务器" />
              </FormItem>
              </Col>
              <Col span="6">
              <FormItem label="端口"
                        prop="port">
                <Input v-model="item.port"
                       placeholder="请输入端口" />
              </FormItem>
              </Col> -->
						</Row>
					</Form>
				</li>
			</ul>
			<div class="submit-wrap">
				<Button
					type="primary"
					:disabled="
						systemActionPermissions.indexOf('btn_setting_config_add') === -1
					"
					@click="handleGlobalSubmit"
					:loading="loading"
					>保存</Button
				>
			</div>
			<!--资料中心参数-->
			<h2 style="margin-top: 10px">邮箱设置</h2>
			<div>
				<!--是否开启退回邮件提醒-->
				<Form
					style="padding: 10px"
					:model="systemEmailForm"
					:rules="systemEmailFormRules"
					ref="systemEmailForm"
					label-position="top"
				>
					<Row :gutter="32">
						<Col span="6">
							<FormItem label="邮箱账号" prop="account">
								<Input
									v-model="systemEmailForm.account"
									placeholder="请输入邮箱账号"
								/>
							</FormItem>
						</Col>
						<Col span="6">
							<FormItem label="密码" prop="password">
								<Input
									type="password"
									readonly
									@on-focus="e => e.target.removeAttribute('readonly')"
									v-model="systemEmailForm.password"
									placeholder="请输入密码"
								/>
							</FormItem>
						</Col>
						<Col span="6">
							<FormItem label="SMTP服务器" prop="host">
								<Input
									v-model="systemEmailForm.host"
									placeholder="请输入SMTP服务器"
								/>
							</FormItem>
						</Col>
						<Col span="6">
							<FormItem label="端口" prop="port">
								<Input
									v-model="systemEmailForm.port"
									placeholder="请输入端口"
								/>
							</FormItem>
						</Col>
					</Row>
				</Form>
			</div>
			<div class="submit-wrap">
				<Button
					type="primary"
					:disabled="
						systemActionPermissions.indexOf('btn_setting_config_add') === -1
					"
					@click="handleEmailSubmit"
					:loading="loading"
					>保存</Button
				>
			</div>
			<h2 style="margin-top: 10px">资料中心参数</h2>
			<ul class="list">
				<li :key="index" class="li-wrap" v-for="(item, index) in docConfigList">
					<p>{{ item.descr }}</p>
					<p>
						<RadioGroup
							:value="item.value"
							@on-change="checked => switchDocConfigChange(index, checked)"
						>
							<Radio
								:disabled="
									systemActionPermissions.indexOf('btn_setting_config_add') ===
									-1
								"
								:label="1"
							>
								不限制格式
							</Radio>
							<Radio
								:disabled="
									systemActionPermissions.indexOf('btn_setting_config_add') ===
									-1
								"
								:label="0"
							>
								限制上传文件格式为pdf
							</Radio>
						</RadioGroup>
					</p>
				</li>
			</ul>
			<div class="submit-wrap">
				<Button
					type="primary"
					:disabled="
						systemActionPermissions.indexOf('btn_setting_config_add') === -1
					"
					@click="handleProcessSubmit('DOCUMENT_UPLOAD_CONFIG')"
					:loading="loading"
					>保存</Button
				>
			</div>
			<!--流程参数-->
			<!--<h2 style="margin-top: 10px">流程参数</h2>-->
			<!--<ul class="list" v-if="processList && processList.length">-->
			<!--<li class="li-wrap">-->
			<!--<p>后续流程开启需要前置{{env === 'xinhua' ? '完成' : '运行'}}的{{getTxt('START')}}阶段流程数量</p>-->
			<!--<p class="setting-value">-->
			<!--<InputNumber :min="1"-->
			<!--:max="99999"-->
			<!--:step="1"-->
			<!--:precision="0"-->
			<!--v-model="processConfig.START_PROC_CONFIG.startProcInstCount"></InputNumber>-->
			<!--<Button type="primary"-->
			<!--style="float: right"-->
			<!--:disabled="systemActionPermissions.indexOf('btn_setting_config_add') === -1"-->
			<!--@click="handleProcessSubmit('START_PROC_CONFIG')"-->
			<!--:loading="loading">保存</Button>-->
			<!--</p>-->
			<!--</li>-->
			<!--<li class="li-wrap">-->
			<!--<p>是否完成所有流程后开启{{getTxt('END')}}流程</p>-->
			<!--<p class="setting-value">-->
			<!--<i-switch v-model="processConfig.END_PROC_CONFIG.endProcRestrict">-->
			<!--<span slot="open">开</span>-->
			<!--<span slot="close">关</span>-->
			<!--</i-switch>-->
			<!--<Button type="primary"-->
			<!--style="float: right; margin-top: -10px;"-->
			<!--:disabled="systemActionPermissions.indexOf('btn_setting_config_add') === -1"-->
			<!--@click="handleProcessSubmit('END_PROC_CONFIG')"-->
			<!--:loading="loading">保存</Button>-->
			<!--</p>-->
			<!--</li>-->
			<!--<li class="li-wrap">-->
			<!--<p>{{getTxt('END')}}流程最大开启数量</p>-->
			<!--<p class="setting-value">-->
			<!--<InputNumber :min="1"-->
			<!--:max="99999"-->
			<!--:step="1"-->
			<!--:precision="0"-->
			<!--v-model="processConfig.END_PROC_COUNT_CONFIG.endProcInstCount"></InputNumber>-->
			<!--<Button type="primary"-->
			<!--style="float: right"-->
			<!--:disabled="systemActionPermissions.indexOf('btn_setting_config_add') === -1"-->
			<!--@click="handleProcessSubmit('END_PROC_COUNT_CONFIG')"-->
			<!--:loading="loading">保存</Button>-->
			<!--</p>-->
			<!--</li>-->
			<!--</ul>-->
			<!--水印参数-->
			<h2 style="margin-top: 10px">水印参数</h2>
			<ul class="list">
				<li :key="index" class="li-wrap" v-for="(item, index) in waterMarkData">
					<div class="water-mark-rule" v-if="item.type === 'text'">
						<p style="padding-bottom: 6px">{{ item.label }}</p>
						<Poptip
							title="水印名称生成规则"
							trigger="hover"
							transfer
							:offset="130"
							placement="top-start"
							class="pop-rule"
						>
							<Input
								style="width: 70%"
								v-model="item.value"
								placeholder="点击查看 水印名称生成规则, 适用于预览及下载水印；不填，默认是中心名称+登录人姓名"
							/>
							<div slot="content" class="formitem-tooltip-content">
								<p><span>{siteName}</span>中心名称</p>
								<p><span>{userName}</span>登录人姓名</p>
								<p style="margin-top: 10px">例如：{siteName}-{userName}</p>
							</div>
						</Poptip>
					</div>
					<template v-if="item.type === 'onoff'">
						<p>{{ item.label }}</p>
						<p style="margin-top: 10px">
							<i-switch
								:value="!!item.value"
								@on-change="checked => switchWatermarkChange(index, checked)"
							>
								<span slot="open">开</span>
								<span slot="close">关</span>
							</i-switch>
						</p>
					</template>
				</li>
			</ul>
			<div class="submit-wrap">
				<Button
					type="primary"
					:disabled="
						systemActionPermissions.indexOf('btn_setting_config_add') === -1
					"
					@click="handleProcessSubmit('WATER_MARK')"
					:loading="loading"
					>保存</Button
				>
			</div>
			<!--申办方手册下载参数-->
			<h2 style="margin-top: 10px">申办方手册管理</h2>
			<ul class="list">
				<li :key="index" class="li-wrap" v-for="(item, index) in sponsorData">
					<div class="water-mark-rule" v-if="item.type === 'file'">
						<p style="padding-bottom: 6px">{{ item.label }}</p>
						<Upload
							action="/"
							:default-file-list="sponsorFileList"
							:show-upload-list="false"
							ref="sponsorUpload"
							:disabled="sponsorLoading || !sponsorData[0].value"
							:before-upload="file => beforeUpload(file, 'sponsor')"
						>
							<Button
								:loading="sponsorLoading"
								type="primary"
								:disabled="!sponsorData[0].value"
								>{{
									sponsorFileList && sponsorFileList.length ? "更换" : "上传"
								}}</Button
							>
						</Upload>
						<p
							v-if="sponsorFileList && sponsorFileList.length"
							class="upload-btn"
							@click="handleDownload"
						>
							{{ sponsorFileList[0].fileName }}
						</p>
					</div>
					<template v-if="item.type === 'onoff'">
						<p>{{ item.label }}</p>
						<p style="margin-top: 10px">
							<i-switch
								:value="!!item.value"
								:disabled="sponsorLoading"
								@on-change="checked => switchSponsorChange(index, checked)"
							>
								<span slot="open">开</span>
								<span slot="close">关</span>
							</i-switch>
						</p>
					</template>
				</li>
			</ul>
			<div class="submit-wrap">
				<Button
					type="primary"
					:loading="loading || sponsorLoading"
					@click="handleProcessSubmit('SPONSOR')"
					>保存</Button
				>
			</div>
			<div
				style="position: relative"
				v-if="
					systemActionPermissions.indexOf(
						'btn_setting_user_privacy_agreement'
					) > -1
				"
			>
				<Spin v-if="privacyInitLoading" fix></Spin>
				<h2 style="margin-top: 10px">用户协议和隐私政策管理</h2>
				<div class="li-wrap">
					<p style="padding-bottom: 6px">用户协议</p>
					<Upload
						action="/"
						:default-file-list="agreeFileList"
						:show-upload-list="false"
						:disabled="agreeLoading"
						ref="agreeUpload"
						accept=".pdf,.doc,.docx"
						:before-upload="file => beforeUpload(file, 'agree')"
					>
						<Button :loading="agreeLoading" type="primary">{{
							agreeFileList && agreeFileList.length ? "更换" : "上传"
						}}</Button>
					</Upload>
					<div v-if="agreeFileList && agreeFileList.length" class="upload-btn">
						<p class="upload-file-name" @click="handlePrivew('20')">
							{{ agreeFileList[0].fileName }}
						</p>
						<Icon
							type="ios-close-circle-outline"
							v-if="!agreeLoading"
							@click="handleDel('20')"
							class="icon-close"
						/>
					</div>
				</div>
				<div class="li-wrap">
					<p style="padding-bottom: 6px">隐私政策</p>
					<Upload
						action="/"
						:default-file-list="privacyFileList"
						:show-upload-list="false"
						:disabled="privacyLoading"
						ref="privacyUpload"
						accept=".pdf,.doc,.docx"
						:before-upload="file => beforeUpload(file, 'privacy')"
					>
						<Button :loading="privacyLoading" type="primary">{{
							privacyFileList && privacyFileList.length ? "更换" : "上传"
						}}</Button>
					</Upload>
					<div
						v-if="privacyFileList && privacyFileList.length"
						class="upload-btn"
					>
						<p class="upload-file-name" @click="handlePrivew('21')">
							{{ privacyFileList[0].fileName }}
						</p>
						<Icon
							type="ios-close-circle-outline"
							v-if="!privacyLoading"
							@click="handleDel('21')"
							class="icon-close"
						/>
					</div>
				</div>
			</div>
		</div>
		<!--  pdf 预览-->
		<DocumentPreview
			:title="docTitle"
			:visible="documentPreviewVisible"
			:documentSrc="documentSrc"
			:id="getRandomId"
			@onCancel="documentPreviewVisible = false"
		></DocumentPreview>
	</div>
</template>

<script>
import api from "@/api/setting/config"
import roleApi from "@/api/setting/process"
import { mapState } from "vuex"
import { formDataToMD5 } from "@/utils/util"
import DocumentPreview from "@/components/BusinessComponents/Document/DocumentPreview.vue"

const {
	apiSetList,
	apiUpload,
	apiSetEmailSettings,
	apiGetEmailSettings,
	apiGetSetting,
	apiSetProcessSetting,
	apiSetDefaultTeam,
	apiGetPrivary,
	apiUploadPrivary,
	apiDelPrivary
} = api

const { apiGetRoleList } = roleApi

const typeMap = {
	END_PROC_COUNT_CONFIG: "39",
	START_PROC_CONFIG: "12",
	END_PROC_CONFIG: "13",
	DOCUMENT_UPLOAD_CONFIG: "24",
	WATER_MARK: "29",
	SPONSOR: "40"
}

export default {
	name: "index",
	components: {
		DocumentPreview
	},
	data() {
		return {
			watermarkRule: "",
			globalListData: [],
			docConfigList: [],
			loading: false,
			menuVisible: false,
			formData: {},
			processConfig: {
				START_PROC_CONFIG: {
					remark: "",
					startProcInstCount: 1
				},
				END_PROC_COUNT_CONFIG: {
					remark: "",
					endProcInstCount: null
				},
				END_PROC_CONFIG: {
					remark: "",
					endProcRestrict: false
				}
			},
			defaultTeamRoles: [],
			roleList: [],
			waterMarkData: [],
			sponsorData: [],
			rules: {
				roles: {
					required: true,
					type: "array",
					message: "该项不能为空",
					trigger: "change blur"
				},
				user: {
					required: true,
					message: "该项不能为空",
					trigger: "change blur"
				},
				password: {
					required: true,
					message: "该项不能为空",
					trigger: "change blur"
				},
				host: {
					required: true,
					message: "该项不能为空",
					trigger: "change blur"
				},
				port: {
					required: true,
					message: "该项不能为空",
					trigger: "change blur"
				}
			},
			systemEmailForm: {},
			systemEmailFormRules: {
				account: {
					required: true,
					message: "该项不能为空",
					trigger: "change blur"
				},
				password: {
					required: true,
					message: "该项不能为空",
					trigger: "change blur"
				},
				host: {
					required: true,
					message: "该项不能为空",
					trigger: "change blur"
				},
				port: {
					required: true,
					message: "该项不能为空",
					trigger: "change blur"
				}
			},
			sponsorFileList: [],
			sponsorLoading: false,
			sponsorCurrentFile: {},
			agreeFileList: [],
			agreeLoading: false,
			agreeCurrentFile: {},
			privacyFileList: [],
			privacyLoading: false,
			privacyCurrentFile: {},
			documentPreviewVisible: false,
			documentSrc: "",
			docTitle: "",
			privacyInitLoading: false
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight
		}),
		...mapState("permission", ["systemActionPermissions"]),
		...mapState("user", ["processList"]), // 流程信息
		getRandomId() {
			return `prev${new Date().getTime().toString(32).toUpperCase()}`
		},
		env() {
			return this.$store.state.environment
		}
	},
	created() {
		this.getRoles()
		this.initData()
	},
	methods: {
		getTxt(type) {
			const itemC = this.processList.find(item => item.obj.nickname === type)
			return itemC ? itemC.name : ""
		},
		async getRoles() {
			const resRole = await apiGetRoleList()
			if (resRole) {
				this.roleList = resRole.data
			}
		},
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const resEmail = await apiGetEmailSettings("38")
				if (resEmail) {
					this.systemEmailForm = resEmail && JSON.parse(resEmail.data.data)
				}
				const res = await apiGetSetting({
					type: "onoff"
				})
				const resSetting = await apiGetSetting({
					type: "docconfig"
				})
				if (res && resSetting) {
					const data1 = res.data || []
					const globalListconfig = data1.find(item => item.id === "17")
					this.globalListData =
						globalListconfig && JSON.parse(globalListconfig.data)

					const watermarkConfig = data1.find(item => item.id === "29")
					this.waterMarkData =
						watermarkConfig && JSON.parse(watermarkConfig.data)

					const sponsorConfig = data1.find(item => item.id === "40") // 申办方手册
					this.sponsorData = sponsorConfig && JSON.parse(sponsorConfig.data)
					const fileItem = this.sponsorData.find(item => item.type === "file")
					this.sponsorCurrentFile = fileItem.fileId
						? {
								fileId: fileItem.fileId,
								fileName: fileItem.fileName
							}
						: {}
					this.sponsorFileList = fileItem.fileId
						? [this.sponsorCurrentFile]
						: []
					const data2 = resSetting.data || []
					const docConfig = data2.find(item => item.id === "24")
					this.docConfigList = docConfig && JSON.parse(docConfig.data)

					this.$store.commit("config/updateConfig", [...data1, ...data2])
				}
				const resProcess = await apiGetSetting({
					type: "config"
				})
				if (resProcess) {
					resProcess.data.forEach(item => {
						const obj = JSON.parse(item.data)
						if (item.name === "START_PROC_CONFIG") {
							this.processConfig[item.name] = {
								...obj,
								startProcInstCount: obj.startProcInstCount || 1
							}
						} else if (item.name === "END_PROC_COUNT_CONFIG") {
							this.processConfig[item.name] = {
								...obj,
								endProcInstCount: obj.endProcInstCount || null
							}
						} else {
							this.processConfig[item.name] = obj
						}
					})
				}
				if (
					this.systemActionPermissions.indexOf(
						"btn_setting_user_privacy_agreement"
					) > -1
				) {
					this.getPrivacy()
				}
				this.loading = false
			})
		},
		async getPrivacy() {
			this.agreeFileList = []
			this.agreeCurrentFile = {}
			this.privacyFileList = []
			this.privacyCurrentFile = {}
			this.privacyInitLoading = true
			const resPrivary = await apiGetPrivary()
			if (resPrivary && resPrivary.data) {
				const itemU = resPrivary.data.find(item => item.fileId === "20") // 用户协议
				this.agreeFileList = itemU ? [itemU] : []
				this.agreeCurrentFile = itemU || {}
				const itemP = resPrivary.data.find(item => item.fileId === "21") // 隐私政策
				this.privacyFileList = itemP ? [itemP] : []
				this.privacyCurrentFile = itemP || {}
			}
			this.privacyInitLoading = false
		},
		switchChange(index, checked, key) {
			if (key) {
				this.globalListData[index][key] = Number(checked)
			} else {
				this.globalListData[index].value = Number(checked)
			}
		},
		switchDocConfigChange(index, checked) {
			this.docConfigList[index].value = Number(checked)
		},
		switchWatermarkChange(index, checked) {
			this.waterMarkData[index].value = Number(checked)
		},
		switchSponsorChange(index, checked) {
			this.sponsorData[index].value = Number(checked)
		},
		beforeUpload(file, type) {
			const size = file.size / 1024
			const filemaxsize = 1024 * 1024 * 1 // 1G
			if (size > filemaxsize) {
				this.$Message.error("附件大小不能大于1G")
				return false
			}
			if (type === "agree" || type === "privacy") {
				if (!/\.(pdf|doc|docx)$/.test(file.name)) {
					this.$Message.error("只能上传后缀名为pdf,doc,docx格式的文件")
					return false
				}
			}
			this[`${type}CurrentFile`] = {
				...file,
				fileName: file.name,
				fileId: type === "agree" ? "20" : type === "privacy" ? "21" : ""
			}
			this[`${type}Loading`] = true
			if (type === "sponsor") {
				formDataToMD5(file, md5Str => {
					// 获取md5后进行文件上传
					this.upload(type, file, { md5: md5Str })
				})
			} else {
				this.upload(type, file)
			}
			return false
		},
		upload(type, file, params) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				let res = false
				if (type === "sponsor") {
					res = await apiUpload(fd, params)
				} else if (type === "agree") {
					res = await apiUploadPrivary(fd, "20")
				} else if (type === "privacy") {
					res = await apiUploadPrivary(fd, "21")
				}
				if (res) {
					this.$Message.success("上传成功!")
					if (type === "sponsor" && this[`${type}FileList`].length) {
						this[`${type}CurrentFile`] = {
							...this[`${type}CurrentFile`],
							...res.data
						}
						this.handleProcessSubmit("SPONSOR", "SPONSOR")
					}
					this[`${type}FileList`] = [this[`${type}CurrentFile`]]
				}
				this[`${type}Loading`] = false
			})
		},
		handleDel(fileId) {
			const txt = fileId === "20" ? "用户协议" : "隐私政策"
			this.$Modal.confirm({
				title: "提示",
				content: `您确认删除该${txt}，并使用系统默认预置${txt}吗？`,
				onOk: () => {
					this.$asyncDo(async () => {
						const res = await apiDelPrivary(fileId)
						if (res) {
							this.$Message.success("操作成功")
							this.getPrivacy()
						}
					})
				}
			})
		},
		handleDownload() {
			window.open(`${this.$baseUrl}/common/download/handbook`)
		},
		handlePrivew(type) {
			// 打开用户隐私协议
			switch (type) {
				case "20":
					// this.documentSrc = 'resource/agreement/S-Trial用户协议.pdf';
					this.documentSrc = `${this.$baseUrl}/common/preview/upload-agreement/20`
					this.docTitle = "S-Trial用户协议"
					break
				case "21":
					// this.documentSrc = 'resource/agreement/S-Trial隐私政策.pdf';
					this.documentSrc = `${this.$baseUrl}/common/preview/upload-agreement/21`
					this.docTitle = "S-Trial隐私政策"
					break
				default:
					break
			}
			this.documentPreviewVisible = true
		},
		// 全局参数提交
		handleGlobalSubmit() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiSetList({
					data: this.globalListData
				})
				if (res) {
					this.$Message.success("操作成功!")
					this.initData()
				}
				this.loading = false
			})
		},
		handleEmailSubmit() {
			this.$refs.systemEmailForm.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiSetEmailSettings(
							{
								id: "38",
								data: this.systemEmailForm
							},
							"38"
						)
						if (res) {
							this.$Message.success("操作成功!")
							this.initData()
						}
					})
				}
			})
		},
		// 流程参数提交
		handleProcessSubmit(type, entry) {
			const id = typeMap[type]
			let arr = []
			if (id === "40") {
				arr = this.sponsorData.map((item, index) => {
					if (index === 0) return item
					return {
						...item,
						fileId: this.sponsorCurrentFile.fileId,
						fileName: this.sponsorCurrentFile.fileName
					}
				})
				if (arr[0].value && !arr[1].fileId) {
					this.$Message.error("请上传申办方手册")
					return
				}
			}
			this.$asyncDo(async () => {
				this.loading = true
				let data = ""
				if (id === "24") {
					data = JSON.stringify(this.docConfigList)
				} else if (id === "29") {
					data = JSON.stringify(this.waterMarkData)
				} else if (id === "40") {
					data = JSON.stringify(arr)
				} else {
					data = JSON.stringify(this.processConfig[type])
				}
				const res = await apiSetProcessSetting({ data }, id)
				if (res) {
					this.$Message.success("操作成功!")
					if (!entry) {
						this.initData()
					}
				}
				this.loading = false
			})
		},
		async handleSetDefaultTeam() {
			this.loading = true
			const res = await apiSetDefaultTeam({
				roleIds: this.defaultTeamRoles
			})
			if (res) {
				this.$Message.success("操作成功!")
				this.initData()
			}
			this.loading = false
		}
	}
}
</script>
<style lang="less">
.config-content {
	padding: 20px 0 50px;
	.list {
		list-style: none;
	}
	.li-wrap {
		padding: 10px;
		border-bottom: 1px solid #eee;
	}
	.setting-value {
		margin-top: 10px;
	}
	.submit-wrap {
		padding: 10px;
		text-align: right;
	}
	.water-mark-rule {
		padding: 5px 0 10px;
		.pop-rule {
			width: 100%;
			.ivu-poptip-rel {
				width: 100%;
			}
		}
		.formitem-tooltip-content {
			padding: 10px;
			font-size: 12px;
			color: #333;
			p {
				line-height: 20px;
				white-space: nowrap;
				span {
					width: 90px;
					display: inline-block;
				}
			}
		}
	}
	.upload-btn {
		margin-top: 10px;
		padding: 10px;
		cursor: pointer;
		/*border-bottom: 1px solid #eee;*/
		&:hover {
			background-color: #f3f3f3;
			.icon-close {
				display: inline-block;
			}
		}
		.upload-file-name {
			display: inline-block;
			vertical-align: middle;
			white-space: normal;
			word-break: break-all;
		}
		.icon-close {
			display: none;
			margin-left: 10px;
			vertical-align: middle;
			font-size: 18px;
			&:hover {
				color: #2d8cf0;
			}
		}
	}
}
</style>
